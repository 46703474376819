import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import NumericInput from '../../../../components/DSE/NumericInput';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true
      }
    },
    expectedIntervalInSeconds: {
      type: NumericInput,
      props: {
        precision: 0,
        caption: 'monitoredProcess.expectedIntervalInSeconds'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
